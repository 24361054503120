







































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class SetManagerDialog extends Vue {
  public dialog: boolean = false
  public placeholder: string = 'You haven\'t defined any custom subjects.'

  public created() {
    this.$bus.$on('show-set-manager-dialog', () => this.dialog = true)
  }

  get customSets(): string[] {
    const sets = this.$store.getters.customSets

    if (sets.length === 0) {
      return [this.placeholder]
    } else {
      return sets
    }
  }
}
